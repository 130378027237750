export default {
    createModal: {
        newGroup: `New Group`,
        photo: `Photo`,
        upload: `Upload`,
        name: `Name`,
        namePlaceholder: `Enter group name`,
        description: `Description`,
        showInApp: `Show in app`,
        frequency: `Frequency`,
        location: `Location`,
        enableChat: `Enable chat`,
        makePublic: `Make public`,
        createFromExistingTags: `Create from existing tags`,
        addGroup: `Add Group`,
        groupCreated: `%{name} group created!`,
        numberPeopleAdded: `%{number} people added`,
        goToGroupPage: `Go to group page`,
        goToDashboard: `Go to dashboard`,
        nameMustBe: `Name must be at least 3 characters long`,
        frequencyRequiredWithShowInApp: `Frequency is required when show in app is enabled.`,
        locationRequiredWithShowInApp: `Location is required when show in app is enabled.`
    }
}
