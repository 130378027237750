import { Expose, instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import type { MeResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'
import { transformDate } from '@/functions/utils'
import type { TransformableDate } from '@/types/utility'
import MyOrganization from '@/services/api/transformers/MyOrganization'

export type SerializedMe = Model

export default class MeResource implements CamelCaseKeys<SerializedMe> {
    id!: Model['id']

    oid!: Model['oid']

    username!: Model['username']

    email!: Model['email']

    @Expose({ name: 'first_name' })
    firstName!: Model['first_name']

    @Expose({ name: 'last_name' })
    lastName!: Model['last_name']

    @Expose({ name: 'is_primary' })
    isPrimary!: Model['is_primary']

    @Expose({ name: 'linked_person_id' })
    linkedPersonId!: Model['linked_person_id']

    @Expose({ name: 'created_on' })
    @Transform(({ value }) => transformDate(value))
    createdOn!: TransformableDate<Model['created_on']>

    @Expose({ name: 'last_active' })
    @Transform(({ value }) => transformDate(value))
    lastActive!: TransformableDate<Model['last_active']>

    referrer!: Model['referrer']

    features!: Model['features']

    @Expose({ name: 'mfa_status' })
    mfaStatus!: Model['mfa_status']

    @Expose({ name: 'email_verified' })
    emailVerified!: Model['email_verified']

    roles!: Model['roles']

    @Transform(({ value }) => plainToInstance(MyOrganization, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    organization!: MyOrganization

    preferences!: Model['preferences']

    constructor(values: MeResource) {
        Object.assign(this, values)
    }
}
