/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserField } from '../models/UserField';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserFieldsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all user fields for the authenticated user
     * @returns UserField OK
     * @throws ApiError
     */
    public d38C8Ce7711F9592E7452A1D9B39Be99(): CancelablePromise<Array<UserField>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/fields',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new user field for the authenticated user
     * @returns UserField Created
     * @throws ApiError
     */
    public cacdddedd57Dca4D84E7C58796320({
        requestBody,
    }: {
        /**
         * Array of Tags to add to the group
         */
        requestBody: {
            field_id?: number;
            section?: string;
        },
    }): CancelablePromise<UserField> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/fields',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Delete a user field for the authenticated user
     * @returns UserField OK
     * @throws ApiError
     */
    public a43B8Acb43C6D7Cd75F93Ee2Dfe166({
        fieldId,
    }: {
        fieldId: number,
    }): CancelablePromise<UserField> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/fields/{fieldId}',
            path: {
                'fieldId': fieldId,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
