import type { Ref } from 'vue'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { plainToInstance } from 'class-transformer'
import useApi from '@/composables/api'
import { QUERY_KEYS } from '@/constants/query'
import IntegrationExtendedResource from '@/services/api/transformers/IntegrationExtendedResource'

const useExtension = (id: Ref<number>) => {
    const { api } = useApi()

    const { data, error, isLoading } = useQuery({
        queryKey: QUERY_KEYS.extension(id),
        queryFn: () => api.integrations.getIntegration({ id: id.value! }).then(({ data }) => data),
        select: data => (plainToInstance(IntegrationExtendedResource, data) as IntegrationExtendedResource),
        enabled: computed(() => Boolean(id.value))
    })

    return {
        data,
        error,
        isLoading
    }
}

export default useExtension
