/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PreferenceResource } from '../models/PreferenceResource';
import type { StorePreferencesRequest } from '../models/StorePreferencesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PreferencesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all user preferences.
     * @returns PreferenceResource OK
     * @throws ApiError
     */
    public getPreferences(): CancelablePromise<PreferenceResource> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/preferences',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create new user preferences.
     * @returns PreferenceResource OK
     * @throws ApiError
     */
    public createPreferences({
        requestBody,
    }: {
        /**
         * Preferences details
         */
        requestBody: StorePreferencesRequest,
    }): CancelablePromise<PreferenceResource> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/preferences',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
