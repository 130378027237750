<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { fetchOneEntry, Content } from '@builder.io/sdk-vue/vue3'
import '@builder.io/sdk-vue/vue3/css'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ENV from '@/constants/env'

interface Props {
    apiKey?: string
    path?: string
}

const props = withDefaults(defineProps<Props>(), {
    apiKey: ENV.builderIoApiKey,
    path: window.location.pathname
})

const isFetching = ref(false)
const isContentReady = computed(() => !isFetching.value && content.value)
const content = ref<any>(null)

const fetchContent = async () => {
    isFetching.value = true

    try {
        const res = await fetchOneEntry({
            model: 'page',
            apiKey: props.apiKey,
            userAttributes: {
                urlPath: props.path
            }
        })

        content.value = res
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error Loading Builder.io Content', err)
    }

    isFetching.value = false
}

onMounted(() => fetchContent())
</script>

<template>
    <div>
        <LoadingSpinner
            v-if="isFetching"
            class="!my-16 mx-auto h-16" />
        <Content
            v-else-if="isContentReady"
            model="page"
            class="builder-content"
            :content="content"
            :api-key="apiKey" />
        <slot v-else />
    </div>
</template>
