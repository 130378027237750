/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ApiApplicationsService } from './services/ApiApplicationsService';
import { ApiApplicationsRequestsService } from './services/ApiApplicationsRequestsService';
import { ApiApplicationsTokensService } from './services/ApiApplicationsTokensService';
import { ArchivePeopleService } from './services/ArchivePeopleService';
import { AttachmentsService } from './services/AttachmentsService';
import { AttachmentsFilesService } from './services/AttachmentsFilesService';
import { AuthorizationService } from './services/AuthorizationService';
import { AuthorizationForgotPasswordService } from './services/AuthorizationForgotPasswordService';
import { AuthorizationLoggedInUserService } from './services/AuthorizationLoggedInUserService';
import { AuthorizationTokensService } from './services/AuthorizationTokensService';
import { BillingService } from './services/BillingService';
import { ContributionsSubscriptionsService } from './services/ContributionsSubscriptionsService';
import { EmailsEmailHistoryService } from './services/EmailsEmailHistoryService';
import { EmailsTempService } from './services/EmailsTempService';
import { EventsService } from './services/EventsService';
import { EventsCalendarsService } from './services/EventsCalendarsService';
import { EventsInstancesService } from './services/EventsInstancesService';
import { GroupsService } from './services/GroupsService';
import { GroupsEventsService } from './services/GroupsEventsService';
import { GroupsMembershipService } from './services/GroupsMembershipService';
import { GroupsMembershipBulkActionsService } from './services/GroupsMembershipBulkActionsService';
import { GroupsMembershipFullService } from './services/GroupsMembershipFullService';
import { HistoryService } from './services/HistoryService';
import { IntegrationsService } from './services/IntegrationsService';
import { MessagesService } from './services/MessagesService';
import { MessageTemplatesService } from './services/MessageTemplatesService';
import { MessagingSmsQueueService } from './services/MessagingSmsQueueService';
import { NotificationsService } from './services/NotificationsService';
import { OAuth20Service } from './services/OAuth20Service';
import { OrganizationsService } from './services/OrganizationsService';
import { PeopleService } from './services/PeopleService';
import { PreferencesService } from './services/PreferencesService';
import { ProfileFieldsService } from './services/ProfileFieldsService';
import { SavedSearchesService } from './services/SavedSearchesService';
import { ServicePlansService } from './services/ServicePlansService';
import { ServicePlansSegmentsService } from './services/ServicePlansSegmentsService';
import { ServicePlansTemplatesService } from './services/ServicePlansTemplatesService';
import { SongLibraryAutoReportingService } from './services/SongLibraryAutoReportingService';
import { SongLibraryCombinedSearchService } from './services/SongLibraryCombinedSearchService';
import { SongLibraryExportService } from './services/SongLibraryExportService';
import { SongLibraryImportFromPlanningCenterOnlineService } from './services/SongLibraryImportFromPlanningCenterOnlineService';
import { SongLibraryImportFromSongLibraryService } from './services/SongLibraryImportFromSongLibraryService';
import { SongLibraryInstrumentsService } from './services/SongLibraryInstrumentsService';
import { SongLibrarySongsService } from './services/SongLibrarySongsService';
import { SongLibrarySongSelectSongDetailsService } from './services/SongLibrarySongSelectSongDetailsService';
import { SongLibrarySongsVersionsService } from './services/SongLibrarySongsVersionsService';
import { SongLibrarySongsVersionsByIdsService } from './services/SongLibrarySongsVersionsByIdsService';
import { SongLibrarySongsVersionsSongSelectImportService } from './services/SongLibrarySongsVersionsSongSelectImportService';
import { SongLibrarySongtagsService } from './services/SongLibrarySongtagsService';
import { SongLibraryTeamService } from './services/SongLibraryTeamService';
import { SongLibraryThemesService } from './services/SongLibraryThemesService';
import { TagsService } from './services/TagsService';
import { TagsFoldersService } from './services/TagsFoldersService';
import { TagsPeopleService } from './services/TagsPeopleService';
import { TextingService } from './services/TextingService';
import { TithelyService } from './services/TithelyService';
import { TithelyAuthService } from './services/TithelyAuthService';
import { UserFieldsService } from './services/UserFieldsService';
import { UserInviteService } from './services/UserInviteService';
import { UsersService } from './services/UsersService';
import { UsersImpersonationService } from './services/UsersImpersonationService';
import { UsersRolesService } from './services/UsersRolesService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly apiApplications: ApiApplicationsService;
    public readonly apiApplicationsRequests: ApiApplicationsRequestsService;
    public readonly apiApplicationsTokens: ApiApplicationsTokensService;
    public readonly archivePeople: ArchivePeopleService;
    public readonly attachments: AttachmentsService;
    public readonly attachmentsFiles: AttachmentsFilesService;
    public readonly authorization: AuthorizationService;
    public readonly authorizationForgotPassword: AuthorizationForgotPasswordService;
    public readonly authorizationLoggedInUser: AuthorizationLoggedInUserService;
    public readonly authorizationTokens: AuthorizationTokensService;
    public readonly billing: BillingService;
    public readonly contributionsSubscriptions: ContributionsSubscriptionsService;
    public readonly emailsEmailHistory: EmailsEmailHistoryService;
    public readonly emailsTemp: EmailsTempService;
    public readonly events: EventsService;
    public readonly eventsCalendars: EventsCalendarsService;
    public readonly eventsInstances: EventsInstancesService;
    public readonly groups: GroupsService;
    public readonly groupsEvents: GroupsEventsService;
    public readonly groupsMembership: GroupsMembershipService;
    public readonly groupsMembershipBulkActions: GroupsMembershipBulkActionsService;
    public readonly groupsMembershipFull: GroupsMembershipFullService;
    public readonly history: HistoryService;
    public readonly integrations: IntegrationsService;
    public readonly messages: MessagesService;
    public readonly messageTemplates: MessageTemplatesService;
    public readonly messagingSmsQueue: MessagingSmsQueueService;
    public readonly notifications: NotificationsService;
    public readonly oAuth20: OAuth20Service;
    public readonly organizations: OrganizationsService;
    public readonly people: PeopleService;
    public readonly preferences: PreferencesService;
    public readonly profileFields: ProfileFieldsService;
    public readonly savedSearches: SavedSearchesService;
    public readonly servicePlans: ServicePlansService;
    public readonly servicePlansSegments: ServicePlansSegmentsService;
    public readonly servicePlansTemplates: ServicePlansTemplatesService;
    public readonly songLibraryAutoReporting: SongLibraryAutoReportingService;
    public readonly songLibraryCombinedSearch: SongLibraryCombinedSearchService;
    public readonly songLibraryExport: SongLibraryExportService;
    public readonly songLibraryImportFromPlanningCenterOnline: SongLibraryImportFromPlanningCenterOnlineService;
    public readonly songLibraryImportFromSongLibrary: SongLibraryImportFromSongLibraryService;
    public readonly songLibraryInstruments: SongLibraryInstrumentsService;
    public readonly songLibrarySongs: SongLibrarySongsService;
    public readonly songLibrarySongSelectSongDetails: SongLibrarySongSelectSongDetailsService;
    public readonly songLibrarySongsVersions: SongLibrarySongsVersionsService;
    public readonly songLibrarySongsVersionsByIds: SongLibrarySongsVersionsByIdsService;
    public readonly songLibrarySongsVersionsSongSelectImport: SongLibrarySongsVersionsSongSelectImportService;
    public readonly songLibrarySongtags: SongLibrarySongtagsService;
    public readonly songLibraryTeam: SongLibraryTeamService;
    public readonly songLibraryThemes: SongLibraryThemesService;
    public readonly tags: TagsService;
    public readonly tagsFolders: TagsFoldersService;
    public readonly tagsPeople: TagsPeopleService;
    public readonly texting: TextingService;
    public readonly tithely: TithelyService;
    public readonly tithelyAuth: TithelyAuthService;
    public readonly userFields: UserFieldsService;
    public readonly userInvite: UserInviteService;
    public readonly users: UsersService;
    public readonly usersImpersonation: UsersImpersonationService;
    public readonly usersRoles: UsersRolesService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.breezechms.test/api/v2',
            VERSION: config?.VERSION ?? '2023-06-09',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.apiApplications = new ApiApplicationsService(this.request);
        this.apiApplicationsRequests = new ApiApplicationsRequestsService(this.request);
        this.apiApplicationsTokens = new ApiApplicationsTokensService(this.request);
        this.archivePeople = new ArchivePeopleService(this.request);
        this.attachments = new AttachmentsService(this.request);
        this.attachmentsFiles = new AttachmentsFilesService(this.request);
        this.authorization = new AuthorizationService(this.request);
        this.authorizationForgotPassword = new AuthorizationForgotPasswordService(this.request);
        this.authorizationLoggedInUser = new AuthorizationLoggedInUserService(this.request);
        this.authorizationTokens = new AuthorizationTokensService(this.request);
        this.billing = new BillingService(this.request);
        this.contributionsSubscriptions = new ContributionsSubscriptionsService(this.request);
        this.emailsEmailHistory = new EmailsEmailHistoryService(this.request);
        this.emailsTemp = new EmailsTempService(this.request);
        this.events = new EventsService(this.request);
        this.eventsCalendars = new EventsCalendarsService(this.request);
        this.eventsInstances = new EventsInstancesService(this.request);
        this.groups = new GroupsService(this.request);
        this.groupsEvents = new GroupsEventsService(this.request);
        this.groupsMembership = new GroupsMembershipService(this.request);
        this.groupsMembershipBulkActions = new GroupsMembershipBulkActionsService(this.request);
        this.groupsMembershipFull = new GroupsMembershipFullService(this.request);
        this.history = new HistoryService(this.request);
        this.integrations = new IntegrationsService(this.request);
        this.messages = new MessagesService(this.request);
        this.messageTemplates = new MessageTemplatesService(this.request);
        this.messagingSmsQueue = new MessagingSmsQueueService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.oAuth20 = new OAuth20Service(this.request);
        this.organizations = new OrganizationsService(this.request);
        this.people = new PeopleService(this.request);
        this.preferences = new PreferencesService(this.request);
        this.profileFields = new ProfileFieldsService(this.request);
        this.savedSearches = new SavedSearchesService(this.request);
        this.servicePlans = new ServicePlansService(this.request);
        this.servicePlansSegments = new ServicePlansSegmentsService(this.request);
        this.servicePlansTemplates = new ServicePlansTemplatesService(this.request);
        this.songLibraryAutoReporting = new SongLibraryAutoReportingService(this.request);
        this.songLibraryCombinedSearch = new SongLibraryCombinedSearchService(this.request);
        this.songLibraryExport = new SongLibraryExportService(this.request);
        this.songLibraryImportFromPlanningCenterOnline = new SongLibraryImportFromPlanningCenterOnlineService(this.request);
        this.songLibraryImportFromSongLibrary = new SongLibraryImportFromSongLibraryService(this.request);
        this.songLibraryInstruments = new SongLibraryInstrumentsService(this.request);
        this.songLibrarySongs = new SongLibrarySongsService(this.request);
        this.songLibrarySongSelectSongDetails = new SongLibrarySongSelectSongDetailsService(this.request);
        this.songLibrarySongsVersions = new SongLibrarySongsVersionsService(this.request);
        this.songLibrarySongsVersionsByIds = new SongLibrarySongsVersionsByIdsService(this.request);
        this.songLibrarySongsVersionsSongSelectImport = new SongLibrarySongsVersionsSongSelectImportService(this.request);
        this.songLibrarySongtags = new SongLibrarySongtagsService(this.request);
        this.songLibraryTeam = new SongLibraryTeamService(this.request);
        this.songLibraryThemes = new SongLibraryThemesService(this.request);
        this.tags = new TagsService(this.request);
        this.tagsFolders = new TagsFoldersService(this.request);
        this.tagsPeople = new TagsPeopleService(this.request);
        this.texting = new TextingService(this.request);
        this.tithely = new TithelyService(this.request);
        this.tithelyAuth = new TithelyAuthService(this.request);
        this.userFields = new UserFieldsService(this.request);
        this.userInvite = new UserInviteService(this.request);
        this.users = new UsersService(this.request);
        this.usersImpersonation = new UsersImpersonationService(this.request);
        this.usersRoles = new UsersRolesService(this.request);
    }
}

