import type { RouteRecordRaw } from 'vue-router'
import login from '@/routes/login'
import account from '@/routes/account'
import events from '@/routes/events'
import songs from '@/routes/songs'
import groups from '@/routes/groups'
import extensions from '@/routes/extensions'
import followups from '@/routes/followups'
import forms from '@/routes/forms'
import give from '@/routes/give'
import history from '@/routes/history'
import payments from '@/routes/payments'
import people from '@/routes/people'
import tags from '@/routes/tags'
import dashboard from '@/routes/dashboard'
import errors from '@/routes/errors'

export default [
    {
        path: `/`,
        component: () => import('@/pages/index.vue')
    },
    ...account,
    ...dashboard,
    ...errors,
    ...events,
    ...songs,
    ...extensions,
    ...followups,
    ...forms,
    ...give,
    ...login,
    ...history,
    ...payments,
    ...people,
    ...tags,
    ...groups
] as RouteRecordRaw[]
