import archives from '@/assets/icons/archives.svg'
import attachments from '@/assets/icons/attachments.svg'
import automate from '@/assets/icons/automate.svg'
import bill from '@/assets/icons/bill.svg'
import breezeB from '@/assets/icons/breeze-b.svg'
import bulktask from '@/assets/icons/bulktask.svg'
import cancel from '@/assets/icons/cancel.svg'
import caretDown from '@/assets/icons/caret-down.svg'
import caretUp from '@/assets/icons/caret-up.svg'
import chartBar from '@/assets/icons/chart-bar.svg'
import check from '@/assets/icons/check.svg'
import chevronDown from '@/assets/icons/chevron-down.svg'
import chevronRight from '@/assets/icons/chevron-right.svg'
import circleCheck from '@/assets/icons/circle-check.svg'
import circleCheckFill from '@/assets/icons/check-circle-fill.svg'
import code from '@/assets/icons/code.svg'
import download from '@/assets/icons/download.svg'
import email from '@/assets/icons/email.svg'
import envelope from '@/assets/icons/envelope-fill.svg'
import extensions from '@/assets/icons/extensions.svg'
import events from '@/assets/icons/events.svg'
import fields from '@/assets/icons/fields.svg'
import filters from '@/assets/icons/filters.svg'
import gift from '@/assets/icons/gift.svg'
import help from '@/assets/icons/help.svg'
import history from '@/assets/icons/history.svg'
import home from '@/assets/icons/home.svg'
import info from '@/assets/icons/info.svg'
import list from '@/assets/icons/list.svg'
import logout from '@/assets/icons/logout.svg'
import menu from '@/assets/icons/menu.svg'
import message from '@/assets/icons/message.svg'
import musicNotesPlus from '@/assets/icons/music-notes-plus.svg'
import notification from '@/assets/icons/notification.svg'
import password from '@/assets/icons/password.svg'
import people from '@/assets/icons/people.svg'
import plan from '@/assets/icons/plan.svg'
import preferences from '@/assets/icons/preferences.svg'
import report from '@/assets/icons/report.svg'
import roles from '@/assets/icons/roles.svg'
import saved from '@/assets/icons/saved.svg'
import search from '@/assets/icons/search.svg'
import settings from '@/assets/icons/settings.svg'
import tags from '@/assets/icons/tags.svg'
import template from '@/assets/icons/template.svg'
import text from '@/assets/icons/text.svg'
import times from '@/assets/icons/times.svg'
import tithely from '@/assets/icons/tithely.svg'
import user from '@/assets/icons/user.svg'
import volunteers from '@/assets/icons/volunteers.svg'
import ellipsisMenu from '@/assets/icons/ellipsis-menu.svg'
import xCircle from '@/assets/icons/x-circle-fill.svg'

export default {
    archives,
    attachments,
    automate,
    bill,
    'breeze-b': breezeB,
    bulktask,
    cancel,
    check,
    code,
    download,
    ellipsisMenu,
    email,
    envelope,
    events,
    extensions,
    fields,
    filters,
    gift,
    help,
    history,
    home,
    info,
    list,
    logout,
    menu,
    message,
    musicNotesPlus,
    notification,
    password,
    people,
    plan,
    preferences,
    report,
    roles,
    saved,
    search,
    settings,
    tags,
    template,
    text,
    times,
    tithely,
    user,
    volunteers,
    'caret-down': caretDown,
    'caret-up': caretUp,
    'chart-bar': chartBar,
    'chevron-right': chevronRight,
    'chevron-down': chevronDown,
    'circle-check': circleCheck,
    'circle-check-fill': circleCheckFill,
    'x-circle': xCircle
} as Record<string, string>
