/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginConfigResponse } from '../models/LoginConfigResponse';
import type { LoginResponse } from '../models/LoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getAuthConfig({
        subdomain,
    }: {
        /**
         * The subdomain for the organization being logged into.
         */
        subdomain: string,
    }): CancelablePromise<{
        data: LoginConfigResponse;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/config',
            query: {
                'subdomain': subdomain,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Log in to Breeze.
     * Standard Username/Password login.
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public login({
        requestBody,
    }: {
        /**
         * Log in credentials
         */
        requestBody: {
            username: string;
            password: string;
            /**
             * The subdomain for the organization being logged into.
             */
            subdomain: string;
            /**
             * Login version 1=Standard, 2=MFA
             */
            version?: number;
            /**
             * Required when 2FA is enabled
             */
            '2fa_code'?: string;
            /**
             * Indicates a login using a trusted device
             */
            use_safe_device?: boolean;
            /**
             * Device token for trusted device
             */
            device_token?: string;
        },
    }): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                428: `Multi-factor authentication required`,
            },
        });
    }

    /**
     * Log the user out (Invalidate the token).
     * @returns any OK
     * @throws ApiError
     */
    public logout({
        requestBody,
    }: {
        /**
         * Deletes session key of provided.
         */
        requestBody: {
            session_key?: string;
        },
    }): CancelablePromise<{
        /**
         * Successfully logged out
         */
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Resolve a one-time login token into
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public loginUsingToken({
        requestBody,
    }: {
        /**
         * Log in credentials
         */
        requestBody: {
            token: string;
            /**
             * The subdomain for the organization being logged into.
             */
            subdomain: string;
        },
    }): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/one-time-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Resolve tithe.ly token
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public loginTithely({
        requestBody,
    }: {
        /**
         * Get credentials from tithely token
         */
        requestBody: {
            token: string;
        },
    }): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/tithely',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
            },
        });
    }

}
