/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MyOrganization } from './MyOrganization';
import type { MyPusher } from './MyPusher';

/**
 * Response from auth/me endpoint.
 */
export type MeResource = {
    /**
     * User's ID.
     */
    id: number;
    /**
     * User's Role ID.
     */
    role_id?: number;
    /**
     * User's Organization ID.
     */
    oid: number;
    /**
     * Log in name.
     */
    username: string;
    /**
     * Email address.
     */
    email: string;
    /**
     * True if the email address has been verified.
     */
    email_verified: boolean;
    /**
     * First name.
     */
    first_name: string;
    /**
     * Last name.
     */
    last_name: string;
    /**
     * True if the user is a primary (Admin) user.
     */
    is_primary: boolean;
    /**
     * ID of the person linked to this user.
     */
    linked_person_id: number | null;
    /**
     * Date user record was created.
     */
    created_on: string;
    /**
     * Date user was last active in Breeze.
     */
    last_active: string;
    /**
     * User's unique referral code.
     */
    referrer: string;
    /**
     * Array of feature toggles in use.
     */
    features: Array<string>;
    /**
     * Array of roles(permissions) that this user has.
     */
    roles: Array<string>;
    organization: MyOrganization;
    /**
     * Array of preferences for this user. Types are appended to the key name with a period.
     */
    preferences: Record<string, string>;
    pusher: MyPusher;
    /**
     * Status of MFA for this user.
     */
    mfa_status: MeResource.mfa_status | null;
};

export namespace MeResource {

    /**
     * Status of MFA for this user.
     */
    export enum mfa_status {
        TOTP = 'totp',
        EMAIL = 'email',
    }


}

